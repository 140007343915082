import { IonRow, IonCol, IonLabel, IonToggle, IonTextarea } from '@ionic/vue';
import { mixinFillingAllObject } from './mixins/fillingAllObject';
export default {
  name: 'InternalInformation',
  components: {
    IonRow,
    IonCol,
    IonLabel,
    IonToggle,
    IonTextarea
  },
  mixins: [mixinFillingAllObject],
  props: ['notes'],

  data() {
    return {
      toggleInternalInformation: false
    };
  },

  methods: {
    onChangeNotes(value) {
      this.$emit('update:notes', value);
    }

  }
};