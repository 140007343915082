import { IonRow, IonCol, IonItem, IonLabel, IonCheckbox, IonImg, IonIcon, IonButton } from '@ionic/vue';
import { trashOutline } from 'ionicons/icons';
export default {
  name: 'Signature',
  components: {
    IonRow,
    IonCol,
    IonItem,
    IonLabel,
    IonCheckbox,
    IonImg,
    IonIcon,
    IonButton
  },
  props: ['did_signature_contact', 'did_signature_driver', 'canvas_signature_contact', 'canvas_signature_driver', 'old_draw_signature_contact', 'old_draw_signature_driver', 'no_signature_contact', 'oldRentalCouponSending', 'signature_contact'],
  emits: ['clearSignatureDriver', 'clearSignatureContact', 'startSignatureDriver', 'startSignatureContact', 'update:no_signature_contact'],

  setup() {
    return {
      trashOutline
    };
  }

};