import axios from 'axios';
import { getParams } from '@/services/users';

async function getAllSupervisorOfBuilder(idBuilder) {
  const response = await axios.get('/driver/builder/' + idBuilder + '/supervisor', await getParams());
  return response.data;
}
/**
 * Sending the rental coupon form so that it can be registered after validation by the server
 */


async function storeRentalCoupon(dataRentalCoupon) {
  const response = await axios.post('/driver/rentalCoupon/create', dataRentalCoupon, await getParams());
  return response.data;
}
/**
 * Get a rental coupon
 * @param id // Id of Rental coupon
 */


async function fetchRentalCoupon(id) {
  const response = await axios.get('/driver/rentalCoupon/' + id, await getParams());
  return response.data;
}
/**
 * Update a rental coupon
 */


async function updateRentalCoupon(dataRentalCoupon) {
  const response = await axios.post('/driver/rentalCoupon/' + dataRentalCoupon.id + '/update', dataRentalCoupon, await getParams());
  return response.data;
}
/**
 * Deleting a rental coupon
 * @param id // Id of Rental coupon
 */


async function destroyRentalCoupon(id) {
  const response = await axios.delete('/driver/rentalCoupon/' + id, await getParams());
  return response.data;
}

async function sendRentalCoupon(id) {
  const response = await axios.post('/driver/rentalCoupon/' + id + '/send', null, await getParams());
  return response.data;
}

async function allRentalCoupon(id) {
  const response = await axios.get('/driver/' + id + '/listRentalCoupon', await getParams());
  return response.data;
}

export { getAllSupervisorOfBuilder, storeRentalCoupon, fetchRentalCoupon, updateRentalCoupon, destroyRentalCoupon, sendRentalCoupon, allRentalCoupon };