import { IonCard, IonCardContent, IonLabel, IonGrid, IonCol, IonRow, IonButton, IonIcon, alertController, toastController, loadingController } from '@ionic/vue';
import { trashOutline, mailOutline, sendOutline } from 'ionicons/icons';
import { convertDateStartDateEndToLisibleDate } from '@/services/utils/dateUtils';
import { destroyRentalCoupon, sendRentalCoupon } from '@/services/driver/rentalCoupon';
import utils from '@/services/utils/Utils';
export default {
  name: 'RentalCoupon',
  components: {
    IonCard,
    IonCardContent,
    IonLabel,
    IonGrid,
    IonCol,
    IonRow,
    IonButton,
    IonIcon
  },

  data() {
    return {
      flashMessage: ''
    };
  },

  props: {
    rentalCoupon: {},
    typeMission: String
  },

  setup() {
    return {
      trashOutline,
      mailOutline,
      sendOutline
    };
  },

  computed: {
    /**
     * Get date in desired format
     */
    date() {
      if (this.rentalCoupon && this.rentalCoupon.start && this.rentalCoupon.end) {
        return convertDateStartDateEndToLisibleDate(this.rentalCoupon.start, this.rentalCoupon.end);
      }

      return '';
    }

  },
  methods: {
    async presentDeleteCoupon() {
      const alert = await alertController.create({
        header: 'Suppression du  bon ' + this.rentalCoupon.name,
        message: 'Voulez-vous réellement supprimer ce bon de location ?',
        buttons: [{
          text: 'Annuler',
          role: 'cancel',
          handler: () => {}
        }, {
          text: 'Valider',
          handler: async () => {
            const response = await destroyRentalCoupon(this.rentalCoupon.id);
            const toast = await toastController.create({
              message: response.flash_message,
              duration: 3000,
              color: 'success',
              position: 'top',
              cssClass: 'toast-rental-coupon'
            });
            await toast.present();
            document.getElementById('refresher-detail').click();
          }
        }]
      });
      return alert.present();
    },

    async resendCoupon() {
      const alert = await alertController.create({
        header: 'Renvoyer le bon ' + this.rentalCoupon.name,
        message: 'Voulez-vous réellement envoyer une nouvelle fois ce bon de location ?',
        buttons: [{
          text: 'Annuler',
          role: 'cancel',
          handler: () => {}
        }, {
          text: 'Valider',
          handler: async () => {
            const loading = await loadingController.create({
              message: "En cours d'envoi..."
            });
            await loading.present();
            let response;

            try {
              response = await sendRentalCoupon(this.rentalCoupon.id);
            } catch (e) {
              await loading.dismiss();
              await utils.onError(e);
              return;
            }

            await loading.dismiss();
            const toast = await toastController.create({
              message: response.flash_message,
              duration: 3000,
              color: 'success',
              position: 'top',
              cssClass: 'toast-rental-coupon'
            });
            await toast.present();
            document.getElementById('refresher-detail').click();
          }
        }]
      });
      return alert.present();
    },

    goToRentalCoupon() {
      if (this.typeMission === 'reservation') {
        this.$router.push('/chantier/' + this.rentalCoupon.reservation_id + '/' + this.rentalCoupon.ride_id + '/bonLocation/' + this.rentalCoupon.id);
        return;
      }

      if (this.typeMission === 'transfert') {
        this.$router.push('/transfert/' + this.rentalCoupon.transfer_id + '/' + this.rentalCoupon.ride_id + '/' + this.rentalCoupon.item_id + '/bonLocation/' + this.rentalCoupon.id);
        return;
      }

      this.$router.push('/livraison/' + this.rentalCoupon.delivery_id + '/' + this.rentalCoupon.ride_id + '/' + this.rentalCoupon.item_id + '/bonLocation/' + this.rentalCoupon.id);
    }

  }
};