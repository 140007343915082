import { IonIcon } from '@ionic/vue';
import { createOutline } from 'ionicons/icons';
export default {
  name: 'NewRentalCoupon',
  components: {
    IonIcon
  },
  props: {
    typeMission: String
  },

  setup() {
    return {
      createOutline
    };
  },

  methods: {
    goToGoodPage() {
      let link = '/';

      if (this.typeMission == 'reservation') {
        link += 'chantier/' + this.$route.params.id;
        link += '/' + this.$route.params.idRide;
      } else {
        link += this.typeMission + '/' + this.$route.params.id + '/' + this.$route.params.idRide + '/' + this.$route.params.idItem;
      }

      link += '/bonLocation/create';
      this.$router.push(link);
    }

  }
};