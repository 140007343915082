import { IonPage, IonContent, IonLabel, IonRefresher, IonRefresherContent } from '@ionic/vue';
import WhiteHeader from '@/components/driver/header/WhiteHeader';
import { getInfo } from '@/services/users';
import { allRentalCoupon } from '@/services/driver/rentalCoupon';
import RentalCoupon from '@/components/driver/card/RentalCoupon';
import { ref } from 'vue';
import { taskToDo } from '@/services/utils/dateUtils';
export default {
  name: 'AllRentalCoupon',
  components: {
    RentalCoupon,
    WhiteHeader,
    IonPage,
    IonContent,
    IonLabel,
    IonRefresher,
    IonRefresherContent
  },

  data() {
    return {
      agent: {},
      listRentalCoupon: {},
      isDisabled: false
    };
  },

  setup() {
    const content = ref();
    /**
     * Allows to know if the mission corresponds to the mission of the day
     */

    const toDo = end => {
      let info = taskToDo(end);
      return info;
    };

    return {
      content,
      toDo
    };
  },

  // Launch auto-refresh when page gets displayed
  ionViewDidEnter() {
    this.doRefresh();
    this.refreshInterval = setInterval(() => {
      this.doRefresh();
    }, 60 * 1000);
  },

  // Stop auto-refresh when page quits
  ionViewDidLeave() {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
      this.refreshInterval = null;
    }
  },

  async mounted() {
    const {
      userData
    } = await getInfo();
    this.agent = userData.agent;
    this.listRentalCoupon = await allRentalCoupon(this.agent.id);
  },

  methods: {
    getTypeRentalCoupon(rentalCoupon) {
      if (rentalCoupon.kind === 'transfer') {
        return 'transfert';
      } else if (rentalCoupon.kind === 'delivery') {
        return 'livraison';
      }

      return 'reservation';
    },

    async doRefresh(event) {
      if (!this.agent || !this.agent.id) return;

      try {
        this.listRentalCoupon = await allRentalCoupon(this.agent.id);
      } catch (_unused) {
        console.log('Unable to force refresh planning.');
      }

      if (event && event.target && event.target.complete) {
        event.target.complete();
      }
    }

  }
};