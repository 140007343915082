import dayjs from 'dayjs';
import axios from 'axios';
import { addOutline } from 'ionicons/icons';
import WhiteHeader from '@/components/driver/header/WhiteHeader';
import { getInfo, getParams } from '@/services/users';
import { makeWeek } from '@/services/utils/reportUtils';
export default {
  components: {
    WhiteHeader
  },

  setup() {
    return {
      addOutline
    };
  },

  ionViewDidEnter() {
    this.getStatuses();
  },

  data() {
    return {
      reports: {},
      weeks: []
    };
  },

  async mounted() {
    this.agent = (await getInfo()).agent;
    this.initList();
  },

  methods: {
    initList() {
      // Take last 4 weeks, including current
      const now = dayjs();
      const weeks = [makeWeek(now.startOf('week')), makeWeek(now.startOf('week').add(-7, 'days')), makeWeek(now.startOf('week').add(-14, 'days')), makeWeek(now.startOf('week').add(-21, 'days'))];
      this.weeks = weeks;
    },

    async getStatuses() {
      const response = await axios.get('/driver/time-report', await getParams());
      this.reports = response.data;
    },

    async doRefresh(event) {
      try {
        await this.getStatuses();
        event.target.complete();
      } catch (e) {
        event.target.cancel();
      }
    },

    openDetail(week) {
      this.$router.push(`/time-reports/${week.days[0].date}`);
    }

  }
};