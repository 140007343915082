const utils = {}; // List of categories that ignore machine "translation"

const categoryOnly = ['tracteurbenne', // Has to be first, because else, conflict with "tracteur"
'bulldozer', 'aspiratrice', 'compacteur', 'niveleuse', 'porteengin', 'tombereau', 'toupie', 'tractopelle', 'tracteur', 'vl', 'balayeuse', 'pata', 'repandeuse', 'epandeur', 'malaxeur', 'hydrocureur', 'concasseur', 'crible']; // List of avaible image names

const availableImages = ['camion-4x2', 'camion-6x4', 'camion-8x4', 'semiremorque-2essieux', 'semiremorque-3essieux', 'pelle-pneu', 'pelle-chaineacier', 'pelle-chainecaoutchouc', 'chargeuse-pneu', 'chargeuse-chaineacier', 'chargeuse-chainecaoutchouc', 'raboteuse-pneu', 'raboteuse-chainecaoutchouc', 'finisseur-pneu', 'finisseur-chainecaoutchouc'].concat(categoryOnly);

utils.getImageForCategory = function getImageForCategory(fullCategory) {
  let filename = fullCategory; // If file is in "categoryOnly"

  categoryOnly.some(category => {
    if (fullCategory.includes(category)) {
      filename = category;
      return true;
    }

    return false;
  });

  if (availableImages.indexOf(filename) > -1) {
    return `/assets/images/engins/${filename}.png`;
  } // Check if a default possibility is here


  availableImages.some(image => {
    if (image.includes(fullCategory)) {
      filename = image;
      return true;
    }

    return false;
  });

  if (availableImages.indexOf(filename) > -1) {
    return `/assets/images/engins/${filename}.png`;
  }
  /**
   * When no image is found, this mean its settings is not in the ballpark
   * For example, "camion" with "chenille-acier", with is not possible
   *
   * We return a default picture for this,
   * and this situation is made to handle kind of "pending bookings",
   * which have no real machine linked, but will be assigned later
   */


  return '/assets/images/engins/attente.png';
};

export default utils;