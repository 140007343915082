import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.regexp.exec.js";
import { IonGrid, IonRow, IonCol, IonSegment, IonSegmentButton, IonLabel, IonSelectOption, IonSelect, IonIcon, IonChip, IonFabButton, IonInput, IonButton, pickerController } from '@ionic/vue';
import utils from '@/services/utils/Utils';
import { addOutline, removeOutline, trashOutline, exitOutline, close } from 'ionicons/icons';
import { alertController } from '@ionic/vue';
import { defineComponent } from 'vue';

const dayjs = require('dayjs');

require('dayjs/locale/fr');

dayjs.locale('fr');
export default defineComponent({
  name: 'Prestation',
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    IonSelectOption,
    IonSelect,
    IonIcon,
    IonChip,
    IonFabButton,
    IonInput,
    IonButton
  },
  props: ['segmentActivities', 'segmentPrestation', 'segmentBadWeather', 'segmentMaintenance', 'times', 'oldRentalCouponSending', 'readableDate', 'start', 'end', 'initialized', 'pricingInformation', 'amountFlatRates', 'modifiers', 'selectedModifiers', 'edit'],

  setup() {
    const customAlertOptions = {
      subHeader: 'Selectionnez une valeur',
      translucent: true
    };
    return {
      customAlertOptions,
      addOutline,
      removeOutline,
      trashOutline,
      exitOutline,
      close
    };
  },

  data() {
    return {
      addingModifier: null,
      toggleActivity: true,
      timeDay: utils.generateArray(6, 0.25),
      modifierTab: utils.generateArray(100, 0.5),
      possibleHours: utils.generateArray(12).join(','),
      roundTab: utils.generateArray(50, 1),
      addedModifiers: [],
      notAddedModifiers: []
    };
  },

  methods: {
    onChangeSegmentActivities(value) {
      this.$emit('update:segmentActivities', value);
    },

    onChangeTypeTimesActivity(name, value) {
      let newTimes = this.times;
      newTimes[name].type = value;
      this.$emit('update:times', newTimes);
    },

    onChangeTimes(name, value, key) {
      if (value === '') {
        if (this.pricingInformation.unit_primary === 'heure') {
          value = '00:00';
        } else {
          value = '0';
        }
      } // need to get the good article for the good id


      let filteredArticle = null;
      this.amountFlatRates.forEach(amountRate => {
        if (amountRate.value === value) {
          filteredArticle = amountRate;
        }
      });
      const goodArticle = filteredArticle ? filteredArticle : this.pricingInformation;
      let newTimes = this.times;

      if (this.pricingInformation.unit_primary === 'heure' || name !== 'prestation') {
        newTimes[name].hoursTimes.values[key].value = value;

        if (name === 'prestation') {
          newTimes[name].hoursTimes.values[key].id = goodArticle.id;
          newTimes[name].hoursTimes.values[key].isOverride = goodArticle.isOverride;
        }

        this.onChangeTypeTimesActivity(name, 'hours');
      } else if (this.pricingInformation.unit_primary === 'jour') {
        newTimes[name].daysTimes.values[key].value = value;

        if (name === 'prestation') {
          newTimes[name].daysTimes.values[key].id = goodArticle.id;
          newTimes[name].daysTimes.values[key].isOverride = goodArticle.isOverride;
        }

        this.onChangeTypeTimesActivity(name, 'days');
      } else if (this.pricingInformation.unit_primary === 'forfait') {
        newTimes[name].packageTimes.values[key].value = value;

        if (name === 'prestation') {
          newTimes[name].packageTimes.values[key].id = goodArticle.id;
          newTimes[name].packageTimes.values[key].isOverride = goodArticle.isOverride;
        }

        this.onChangeTypeTimesActivity(name, 'package');
      } else {
        newTimes[name].othersTimes.values[key].value = value;

        if (name === 'prestation') {
          newTimes[name].othersTimes.values[key].id = goodArticle.id;
          newTimes[name].othersTimes.values[key].isOverride = goodArticle.isOverride;
        }

        this.onChangeTypeTimesActivity(name, goodArticle.unit_primary);
      }

      this.$emit('update:times', newTimes);
    },

    onModifierClick(modifier, type) {
      if (this.oldRentalCouponSending) return;

      if (type === 'add') {
        this.addingModifier = modifier;
        this.addedModifiers.push(modifier);
        this.notAddedModifiers.splice(this.notAddedModifiers.indexOf(modifier), 1);
      } else {
        this.addingModifier = null;
        this.addedModifiers.splice(this.addedModifiers.indexOf(modifier), 1);
        this.notAddedModifiers.push(modifier);
        Object.keys(this.readableDate).forEach(key => {
          const value = modifier.unit_primary === 'heure' ? '00:00' : '0';
          this.onChangeModifier(modifier, value, key);
        });
      }
    },

    onChangeModifier(modifier, value, key) {
      let flat_rate = null;
      let unit_secondary = null;
      let filteredModifier = null;

      if (modifier.unit_primary === 'pourcentage' && value !== '0') {
        value = value ? modifier.rate : '0';
      } else {
        flat_rate = value.split(' ')[0];
        unit_secondary = value.split(' ')[1]; // need to get the good modifier for the good id

        filteredModifier = this.modifiers.filter(modifier => {
          return modifier.amount_flat_rate === flat_rate && modifier.unit_secondary === unit_secondary;
        })[0];
      }

      const goodModifier = filteredModifier ? filteredModifier : modifier;
      let oldModifiers = this.selectedModifiers;
      oldModifiers[modifier.name].values[key].value = value;
      oldModifiers[modifier.name].values[key].id = goodModifier.override_id ? goodModifier.override_id : goodModifier.id;
      oldModifiers[modifier.name].values[key].isOverride = !!goodModifier.override_id;
    },

    filteredModifiers(modifierName) {
      return this.modifiers.filter(modifier => {
        return modifier.unit_primary === 'forfait' && modifier.name === modifierName && modifier.unit_secondary;
      });
    },

    async duplicateOnAllDays(value, key, isModifiers = false) {
      // validation pop up
      const popUp = await alertController.create({
        message: "Voulez vous appliquer sur toute la durée de la réservation ?",
        buttons: [{
          text: 'Non',
          role: 'cancel',
          handler: () => {
            return false;
          }
        }, {
          text: 'Oui',
          handler: () => {
            let goodTimes = value.othersTimes;

            if (isModifiers) {
              goodTimes = this.selectedModifiers[value.name];
            } else if (value.type === 'hours') {
              goodTimes = value.hoursTimes;
            } else if (value.type === 'days') {
              goodTimes = value.daysTimes;
            } else if (value.type.includes('package')) {
              goodTimes = value.packageTimes;
            }

            const goodValue = goodTimes.values[key].value;
            Object.keys(this.readableDate).forEach(day => {
              if (isModifiers) {
                this.onChangeModifier(value, goodValue, day);
              } else {
                this.onChangeTimes(value.name, goodValue, day);
              }
            });
            return true;
          }
        }],
        cssClass: ['text-align-center']
      });
      await popUp.present();
    },

    async openPicker(element, key, kind) {
      const isArticle = kind === 'article'; // get current hours and minutes

      const selectedDates = isArticle ? this.times[element.name].hoursTimes.values[key].value.split(':') : this.selectedModifiers[element.name].values[key].value.split(':');
      const picker = await pickerController.create({
        columns: utils.generatePickerColumns(selectedDates[0], selectedDates[1]),
        buttons: [{
          text: 'Annuler',
          role: 'cancel'
        }, {
          text: 'Valider',
          handler: value => {
            const method = isArticle ? this.onChangeTimes : this.onChangeModifier;
            const formattedTime = [value.hours.value, value.minutes.value].join(':');
            method(isArticle ? element.name : element, formattedTime, key);
          }
        }]
      });
      await picker.present();
    }

  },
  watch: {
    modifiers() {
      // we need to use a foreach because it allow to not sync both tab
      this.modifiers.forEach(modifier => this.notAddedModifiers.push(modifier));
    },

    // for edit part --> add selectedModifiers to addedModifiers
    'readableDate': {
      handler() {
        if (!this.modifiers && !this.selectedModifiers.length > 0) return;
        this.modifiers.forEach(modifier => {
          const keys = Object.keys(this.readableDate);
          let bool = false;
          keys.forEach(key => {
            if (this.selectedModifiers[modifier.name].values[key].value !== '0' && this.selectedModifiers[modifier.name].values[key].value !== '00:00') {
              bool = true;
            }
          });
          const arrayColumn = this.addedModifiers.map(item => item.name);

          if (bool && arrayColumn.indexOf(modifier.name) === -1) {
            this.addedModifiers.push(modifier);
            this.notAddedModifiers.splice(this.notAddedModifiers.indexOf(modifier), 1);
          }
        });
      },

      deep: true
    }
  },
  computed: {
    filteredNotAddedModifiers() {
      //map to get an array with only 'name' columns
      const arrayColumn = this.notAddedModifiers.map(item => item.name);
      const arrayAddedColumn = this.addedModifiers.map(item => item.name); // only get unique name and not on added modifiers

      return this.notAddedModifiers.filter((value, index) => {
        return arrayColumn.indexOf(value.name) === index && arrayAddedColumn.indexOf(value.name) === -1;
      });
    }

  }
});