import { IonRow, IonCol, IonLabel, IonToggle, IonTextarea, IonSegment, IonSegmentButton, IonSelectOption, IonSelect, IonInput, IonItem } from '@ionic/vue';
import utils from '@/services/utils/Utils';
export default {
  name: 'Options',
  components: {
    IonRow,
    IonCol,
    IonLabel,
    IonToggle,
    IonTextarea,
    IonSegment,
    IonSegmentButton,
    IonSelectOption,
    IonSelect,
    IonInput,
    IonItem
  },
  props: ['toggleMachineCounter', 'toggleComment', 'pricingFuel', 'machine_counter_start', 'machine_counter_end', 'comments', 'oldRentalCouponSending', 'typeMission', 'segmentContact', 'contact_id', 'supervisorsBuilder', 'typeClient', 'coupon_company', 'contact_name', 'builder_id', 'contact_email', 'contact_phone', 'status', 'oldContactId', 'oldContactEmail'],

  data() {
    return {
      numberAreaWorksite: utils.generateArray(10)
    };
  },

  methods: {
    onChangeContactEmail(value) {
      this.$emit('update:contact_email', value);
    },

    onChangeSegmentContact(value) {
      this.$emit('update:segmentContact', value);
      this.updateSegmentContact(value);
    },

    onChangeContactId(value) {
      this.$emit('update:contact_id', value);
      this.updateContactEmail(value);
    },

    onChangeContactName(value) {
      this.$emit('update:contact_name', value);
    },

    onChangeToggleMachineCounter(value) {
      this.$emit('update:toggleMachineCounter', value);
    },

    onChangeToggleComment(value) {
      this.$emit('update:toggleComment', value);
    },

    onChangeFuel(value) {
      this.$emit('update:pricingFuel', value);
    },

    onChangeMachineCounterStart(value) {
      this.$emit('update:machine_counter_start', value);
    },

    onChangeMachineCounterEnd(value) {
      this.$emit('update:machine_counter_end', value);
    },

    onChangeComments(value) {
      this.$emit('update:comments', value);
    },

    updateContactEmail(contactIdValue) {
      if (this.typeMission !== 'reservation' && this.coupon_company !== this.typeClient) {
        return;
      }

      for (const key in this.supervisorsBuilder) {
        if (this.supervisorsBuilder[key].id === contactIdValue) {
          if (this.typeVue !== 'create' && this.supervisorsBuilder[key].id === this.oldContactId) {
            this.$emit('update:contact_email', this.oldContactEmail);
            return;
          }

          this.$emit('update:contact_email', this.supervisorsBuilder[key].email);
          this.$emit('update:contact_phone', this.supervisorsBuilder[key].phone);
          return;
        }
      }
    },

    updateSegmentContact(segmentValue) {
      if (this.typeMission !== 'reservation' && this.coupon_company !== this.typeClient) {
        return;
      }

      if (segmentValue === 'new') {
        this.$emit('update:contact_email', '');
        this.$emit('update:contact_phone', null);
        return;
      } // if (this.segmentSourceContact === 'available')


      for (const key in this.supervisorsBuilder) {
        if (this.supervisorsBuilder[key].id === this.contact_id) {
          if (this.typeVue !== 'create' && this.supervisorsBuilder[key].id === this.oldContactId) {
            this.$emit('update:contact_email', this.oldContactEmail);
            return;
          }

          this.$emit('update:contact_email', this.supervisorsBuilder[key].email);
          this.$emit('update:contact_phone', this.supervisorsBuilder[key].phone);
          return;
        }
      }
    }

  }
};