import "core-js/modules/es.regexp.exec.js";
import { getParams } from '@/services/users';
import WhiteHeader from '@/components/driver/header/WhiteHeader';
import { chevronUpOutline, chevronDownOutline } from 'ionicons/icons';
import { IonPage, IonContent, IonRow, IonCol, IonButton, IonLabel, IonChip, IonCheckbox, IonTextarea, IonIcon, toastController, IonSelect, IonSelectOption, pickerController } from '@ionic/vue';
import axios from 'axios';
import dayjs from 'dayjs';
import { makeWeek, formatDate } from '@/services/utils/reportUtils';
import utils from '@/services/utils/Utils';
const DAYS = ['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'];
const AREAS = utils.generateArray(15);
const HOURS = utils.generateArray(12).join(',');
export default {
  name: 'TimeReportUpdate',

  setup() {
    return {
      chevronUpOutline,
      chevronDownOutline
    };
  },

  components: {
    WhiteHeader,
    IonPage,
    IonContent,
    IonRow,
    IonButton,
    IonCol,
    IonLabel,
    IonChip,
    IonCheckbox,
    IonTextarea,
    IonIcon,
    IonSelect,
    IonSelectOption
  },

  data() {
    return {
      week: null,
      days: DAYS,
      areas: AREAS,
      possibleHours: HOURS,
      dates: [],
      reports: {}
    };
  },

  mounted() {
    const start = dayjs(this.$route.params.date).startOf('week');
    DAYS.forEach((day, index) => {
      this.dates.push(start.add(index, 'days').format('YYYY-MM-DD'));
    });
    this.week = makeWeek(start);
    this.fetchDetail();
  },

  methods: {
    async fetchDetail() {
      const response = await axios.get(`/driver/time-report/${this.$route.params.date}`, await getParams());
      const reports = response.data;
      this.dates.forEach(date => {
        if (!reports[date]) {
          reports[date] = {
            hours_agent: 0,
            panier_agent: false,
            fiche_agent: false,
            grand_deplacement_agent: false,
            area_worksite_agent: '0',
            // Has to be string, for <option>
            comments_agent: null
          };
        } else {
          // Format it for datepicker
          reports[date].hours_agent = this.floatToString(reports[date].hours_agent); // Format for dropdown

          reports[date].area_worksite_agent = '' + reports[date].area_worksite_agent;
        }
      });
      this.reports = reports;
    },

    formatDate,

    stringToFloat(input) {
      if (input === 0) return 0;
      const time = input.split(':');
      return parseInt(time[0]) + parseInt(time[1]) / 60;
    },

    floatToString(input) {
      const hours = Math.floor(input); // ex: 0,5 become 30

      const minutes = (input - hours) * 60;
      return dayjs().minute(minutes).hour(hours).format('HH:mm');
    },

    getDataToSend(mode) {
      const output = [];
      Object.keys(this.reports).forEach(date => {
        const report = this.reports[date];
        const report2 = JSON.parse(JSON.stringify(report));
        report2.date = date;
        report2.hours_agent = this.stringToFloat(report2.hours_agent); // Do not send if definitive or pristine

        if (report2.status && report2.status === 'sent' || !report2.status && report2.hours_agent === 0 && !report2.panier_agent && !report2.fiche_agent && !report2.grand_deplacement_agent && (!report2.area_worksite_agent || report2.area_worksite_agent === '0') && !report2.comments_agent) {
          return;
        }

        output.push(report2);
      });
      return {
        status: mode,
        entries: output
      };
    },

    async sendAs(status) {
      try {
        await axios.post(`/driver/time-report`, this.getDataToSend(status), await getParams());
        await (await toastController.create({
          cssClass: 'toast-rental-coupon',
          position: 'top',
          duration: 2500,
          message: status === 'draft' ? 'OK, données enregistrées en brouillon.' : 'OK, données envoyées.',
          color: 'success'
        })).present();
      } catch (_unused) {
        await (await toastController.create({
          cssClass: 'toast-rental-coupon',
          position: 'top',
          duration: 2500,
          message: "Erreur lors de l'envoi.",
          color: 'danger'
        })).present();
      }

      this.fetchDetail();
    },

    async openPicker(report) {
      const selectedDates = report.hours_agent !== 0 ? report.hours_agent.split(':') : ['00', '00'];
      const picker = await pickerController.create({
        columns: utils.generatePickerColumns(selectedDates[0], selectedDates[1]),
        buttons: [{
          text: 'Annuler',
          role: 'cancel'
        }, {
          text: 'Valider',
          handler: value => {
            report.hours_agent = [value.hours.value, value.minutes.value].join(':');
          }
        }]
      });
      await picker.present();
    }

  }
};